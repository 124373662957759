/**
 * FortressService Service
 */
export class FortressService {
    constructor() {
        this.API_URL = process.env.REACT_APP_API_URL
    }
    async getAllFortress(){
        const response = await fetch(this.API_URL + '/fortress');
        return response.json();
    }

}