import React, { useState, useEffect } from 'react';
import RawConnectFirstData from './RawConnectFirstData';
import { ConnectFirstService } from '../../../api/ConnectFirst';
import { Box, Tabs, Tab, Snackbar } from '@mui/material';
import CADOpex from './CADOpex';
import CADTrading from './CADTrading';
import USDData from './USDData';
function ConnectFirst() {
    const [rawData, setRawData] = useState([])
    const [loading, setLoading] = useState(true)
    const [activeTab, setActiveTab] = useState(0)

    const connectFirstService = new ConnectFirstService();
    useEffect(() => {
        connectFirstService.getAllConnectFirst().then(rawCF => {
            // console.log('useEffect() rawBcb', rawBcb);
            setRawData(rawCF);
            setLoading(false)
        }).catch(err => {
            console.log(err);
            setLoading(false)
        });
    }, [])

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    }

    return (
        // <div>
        //     <RawConnectFirstData
        //         rawData={rawData}
        //         loading={loading}
        //     />
        // </div>
        <Box
            sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: '100vh', marginTop: 5 }}
        >
            <Tabs
                value={activeTab}
                onChange={handleTabChange}
                orientation="vertical"
                sx={{ borderRight: 1, borderColor: 'divider' }}
                // className={classes.tabsContainer}
            >
                <Tab label="ALL" />
                <Tab label="CAD Trading" />
                <Tab label="CAD Opex" />
                <Tab label="USD" />
            </Tabs>
            <div style={{width: '100%'}}>
                {activeTab === 0 && <RawConnectFirstData rawData={rawData} loading={loading}/>}
                {activeTab === 1 && <CADTrading rawData={rawData} loading={loading}/>}
                {activeTab === 2 && <CADOpex rawData={rawData} loading={loading}/>}
                {activeTab === 3 && <USDData rawData={rawData} loading={loading}/>}
            </div>
        </Box>
    );
}

export default ConnectFirst;