/**
 * Openpayd Service
 */
export class OpenpaydService {
    constructor() {
        this.API_URL = process.env.REACT_APP_API_URL
    }
    async getAllOpenpayd(){
        const response = await fetch(this.API_URL + '/openpayd');
        return response.json();
    }

}