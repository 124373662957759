import React, { useState, useEffect } from 'react';
import RawOpenpayData from './RawOpenpayData';
import { OpenpaydService } from '../../../api/OpenpaydService';
import { Box, Tabs, Tab, Snackbar } from '@mui/material';
import CADData from './CADData';
import USDData from './USDData';
import GBPData from './GBPData';
import { CircularProgress } from '@mui/material';

function Openpayd(props) {
    const [rawData, setRawData] = useState([])
    const [loading, setLoading] = useState(true)
    const [activeTab, setActiveTab] = useState(0)

    const openpaydService = new OpenpaydService();
    useEffect(() => {
        openpaydService.getAllOpenpayd().then(rawCF => {
            // console.log('useEffect() rawBcb', rawBcb);
            setRawData(rawCF);
            setLoading(false)
        }).catch(err => {
            console.log(err);
            setLoading(false)
        });
    }, [])

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    }

    return (
        <div>
            <RawOpenpayData
                rawData={rawData}
                loading={loading}
            />
        </div>
        // <Box
        //     sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: '100vh', marginTop: 5 }}
        // >
        //     <Tabs
        //         value={activeTab}
        //         onChange={handleTabChange}
        //         orientation="vertical"
        //         sx={{ borderRight: 1, borderColor: 'divider' }}
        //         // className={classes.tabsContainer}
        //     >
        //         <Tab label="ALL" />
        //         <Tab label="CAD" />
        //         <Tab label="USD" />
        //         <Tab label="GBP" />
        //     </Tabs>
        //     {loading? 
        //     <CircularProgress />
        //     :<div style={{width: '100%'}}>
        //         {activeTab === 0 && <RawOpenpayData rawData={rawData} loading={loading}/>}
        //         {activeTab === 1 && <CADData rawData={rawData.filter(tx => tx.amount.currency == 'CAD')} loading={loading}/>}
        //         {activeTab === 2 && <USDData rawData={rawData.filter(tx => tx.amount.currency == 'USD')} loading={loading}/>}
        //         {activeTab === 3 && <GBPData rawData={rawData.filter(tx => tx.amount.currency == 'GBP')} loading={loading}/>}
        //     </div>}
        // </Box>
    );
}

export default Openpayd;