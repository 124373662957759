import React, { useState, useEffect } from 'react';
import RawFortressData from './RawFortressData';
import { FortressService } from '../../../api/FortressService';
function Fortress(props) {
    const [rawData, setRawData] = useState([])
    const [loading, setLoading] = useState(true)
    const fortressService = new FortressService();
    useEffect(() => {
        fortressService.getAllFortress().then(rawCF => {
          // console.log('useEffect() rawBcb', rawBcb);
            setRawData(rawCF);
            setLoading(false)
        }).catch(err => {
            console.log(err);
            setLoading(false)
        });
    }, [])
    return (
        <div>
            <RawFortressData
                rawData={rawData}
                loading={loading}
            />
        </div>
    );
}

export default Fortress;