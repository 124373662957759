import React, { useState, useEffect } from 'react';
import RawFireblocksData from './RawFireblocksData';
import { FireblocksService } from '../../../api/FireblocksService';
import CircularProgress from '@mui/material/CircularProgress';
function Fireblocks(props) {
    const [rawFireblocks, setRawFireblocks] = useState([])
    const [loading, setLoading] = useState(true)
    const fireblocksService = new FireblocksService();
    useEffect(() => {
        fireblocksService.getAllFireblocks().then(rawFireblocks => {
            // console.log('useEffect() rawFireblocks', rawFireblocks);
            setRawFireblocks(rawFireblocks);
            setLoading(false)
        }).catch(err => {
            console.log(err)
            setLoading(false)
        });
    }, [])
    return (
        <div>
            {loading? 
            <CircularProgress/> 
            :<RawFireblocksData
                rawFireblocks={rawFireblocks}
            ></RawFireblocksData>}
        </div>
    );
}

export default Fireblocks;