import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import copy from 'copy-to-clipboard';
import { Stack } from '@mui/material';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
function TradePreviewDialog({open, submitTicket, handleClose, askingTermsSelect, clientName, tradeTicketID, tradingPair, date, action, spread, amount, spotRate,clientDealtRate, amountToTrade, decimalPlace, dealtDecimal  }) {
    const [isCopied, setIsCopied] = React.useState(false)
    console.log( askingTermsSelect, action, amount, amountToTrade, tradingPair);
    console.log(decimalPlace)
    console.log(dealtDecimal);
    const handleCopyTradeTicket = () => {
        setIsCopied(false)
        let ticket = `*Trade Summary* \n`+
            `Client: ${clientName} \n` +
            `Date: ${date} \n` +
            `CCY Deposited: ${
                askingTermsSelect == 'Fiat'? 
                    action == 'Buy'? parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: dealtDecimal, minimumFractionDigits: dealtDecimal}) + ' ' + tradingPair.split('/')[1]
                    : parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits:decimalPlace, minimumFractionDigits: decimalPlace}) + ' ' + tradingPair.split('/')[0]
                    : action == 'Buy'? parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: decimalPlace, minimumFractionDigits: decimalPlace}) + ' ' + tradingPair.split('/')[1]
                    : parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits:dealtDecimal, minimumFractionDigits: dealtDecimal}) + ' ' + tradingPair.split('/')[0]} \n` +
            `Spot Price: ${
                askingTermsSelect == 'Fiat'? 
                    parseFloat(spotRate).toLocaleString(undefined, {minimumFractionDigits: dealtDecimal, maximumFractionDigits: dealtDecimal}) + ' '+ tradingPair
                    :parseFloat(spotRate).toLocaleString(undefined, {minimumFractionDigits: decimalPlace, maximumFractionDigits: decimalPlace}) + ' '+ tradingPair} \n` +
            `Dealt Rate: ${
                askingTermsSelect == 'Fiat'? 
                    parseFloat(clientDealtRate).toLocaleString(undefined, {minimumFractionDigits: dealtDecimal, maximumFractionDigits: dealtDecimal}) + ' '+ tradingPair
                    : parseFloat(clientDealtRate).toLocaleString(undefined, {minimumFractionDigits: decimalPlace, maximumFractionDigits: decimalPlace}) + ' '+ tradingPair} \n` +
            `CCY Purchased: ${
                askingTermsSelect == 'Fiat'? 
                    action == 'Buy'? parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: decimalPlace, minimumFractionDigits: decimalPlace}) + ' ' + tradingPair.split('/')[0]
                    : parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: dealtDecimal, minimumFractionDigits: dealtDecimal}) + ' ' + tradingPair.split('/')[1]
                    : action == 'Buy'? parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: dealtDecimal, minimumFractionDigits: dealtDecimal}) + ' ' + tradingPair.split('/')[0]
                    : parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: decimalPlace, minimumFractionDigits: decimalPlace}) + ' ' + tradingPair.split('/')[1]
            } \n` +
            `\n` +
            `ID: ${tradeTicketID}`
        copy(ticket)
        setIsCopied(true)
    }
    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    {/* <Typography id="modal-modal-title" variant="h6" component="h2">
                        Trade Summary
                    </Typography> */}
                    <Stack spacing={1}>
                        {isCopied?
                        <Button color='success' onClick={() => handleCopyTradeTicket()}>Copied!</Button>
                        :<Button color='primary' onClick={() => handleCopyTradeTicket()}>Copy Trade Ticket</Button>}
                    </Stack>
                    <Stack>
                        <Typography id="modal-modal-description" sx={{ mt: 2, alignSelf: 'center' }}>
                            {/* <div style={{margin: 0}}>
                                {'*Trade Summary* \n' +
                                `Client: ${clientName} \n` +
                                `Date: ${date} \n` +
                                `CCY Deposited: ${
                                askingTermsSelect == 'Fiat'? 
                                    action == 'Buy'? parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: dealtDecimal, minimumFractionDigits: dealtDecimal}) + ' ' + tradingPair.split('/')[1]
                                    : parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits:decimalPlace, minimumFractionDigits: decimalPlace}) + ' ' + tradingPair.split('/')[0]
                                    : action == 'Buy'? parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: dealtDecimal, minimumFractionDigits: dealtDecimal}) + ' ' + tradingPair.split('/')[1]
                                    : parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits:decimalPlace, minimumFractionDigits: decimalPlace}) + ' ' + tradingPair.split('/')[0]} \n` +
                                `Spot Price: ${parseFloat(spotRate).toLocaleString(undefined, {minimumFractionDigits: dealtDecimal, maximumFractionDigits: dealtDecimal}) + ' '+ tradingPair}\n` +
                                `Dealt Rate: ${parseFloat(clientDealtRate).toLocaleString(undefined, {minimumFractionDigits: dealtDecimal}) + ' '+ tradingPair} \n` +
                                `CCY Purchased: ${
                                askingTermsSelect == 'Fiat'? 
                                action == 'Buy'? parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: decimalPlace, minimumFractionDigits: decimalPlace}) + ' ' + tradingPair.split('/')[0]
                                : parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: decimalPlace, minimumFractionDigits: decimalPlace}) + ' ' + tradingPair.split('/')[1]
                                : action == 'Buy'? parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: decimalPlace, minimumFractionDigits: decimalPlace}) + ' ' + tradingPair.split('/')[0]
                                : parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: decimalPlace, minimumFractionDigits: decimalPlace}) + ' ' + tradingPair.split('/')[1]}`}
                            </div> */}
                            <p style={{margin: 0}}>*Trade Summary*</p>
                            <p style={{margin: 0}}>Client: {clientName}</p>
                            <p style={{margin: 0}}>Date: {date}</p>
                            <p style={{margin: 0}}>CCY Deposited: {
                                askingTermsSelect == 'Fiat'? 
                                    action == 'Buy'? parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: dealtDecimal, minimumFractionDigits: dealtDecimal}) + ' ' + tradingPair.split('/')[1]
                                    : parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits:decimalPlace, minimumFractionDigits: decimalPlace}) + ' ' + tradingPair.split('/')[0]
                                    : action == 'Buy'? parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: decimalPlace, minimumFractionDigits: decimalPlace}) + ' ' + tradingPair.split('/')[1]
                                    : parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits:dealtDecimal, minimumFractionDigits: dealtDecimal}) + ' ' + tradingPair.split('/')[0]}</p>
                            <p style={{margin: 0}}>Spot Price: {
                                askingTermsSelect == 'Fiat'? 
                                    parseFloat(spotRate).toLocaleString(undefined, {minimumFractionDigits: dealtDecimal, maximumFractionDigits: dealtDecimal}) + ' '+ tradingPair
                                    :parseFloat(spotRate).toLocaleString(undefined, {minimumFractionDigits: decimalPlace, maximumFractionDigits: decimalPlace}) + ' '+ tradingPair}
                            </p>
                            <p style={{margin: 0}}>Dealt Rate: {
                                askingTermsSelect == 'Fiat'? 
                                    parseFloat(clientDealtRate).toLocaleString(undefined, {minimumFractionDigits: dealtDecimal, maximumFractionDigits: dealtDecimal}) + ' '+ tradingPair
                                    : parseFloat(clientDealtRate).toLocaleString(undefined, {minimumFractionDigits: decimalPlace, maximumFractionDigits: decimalPlace}) + ' '+ tradingPair
                            }</p>
                            <p style={{margin: 0}}>CCY Purchased: {
                                askingTermsSelect == 'Fiat'? 
                                    action == 'Buy'? parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: decimalPlace, minimumFractionDigits: decimalPlace}) + ' ' + tradingPair.split('/')[0]
                                    : parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: dealtDecimal, minimumFractionDigits: dealtDecimal}) + ' ' + tradingPair.split('/')[1]
                                    : action == 'Buy'? parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: dealtDecimal, minimumFractionDigits: dealtDecimal}) + ' ' + tradingPair.split('/')[0]
                                    : parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: decimalPlace, minimumFractionDigits: decimalPlace}) + ' ' + tradingPair.split('/')[1]
                            }</p>
                            <p style={{marginTop: 0}}></p>
                            <p style={{marginTop: 0}}>ID: {tradeTicketID}</p>
                            {/* <p>Trading Pair: {tradingPair}</p>
                            <p>Action: {action}</p>
                            <p>Spread: {spread}</p>
                            <p>Amount: {amount}</p>
                            <p>Spot Rate: {spotRate}</p>
                            <p>Client Dealt Rate: {clientDealtRate}</p>
                            <p>Amount to Trade: {amountToTrade}</p> */}
                        </Typography>
                    </Stack>
                    <Stack spacing={1}>
                        <Button variant='contained' color='primary' onClick={submitTicket}>Submit</Button>
                        <Button variant='contained' color='error' onClick={handleClose}>Cancel</Button>
                        
                    </Stack>
                    
                </Box>
            </Modal>
        </div>
    );
}

export default TradePreviewDialog;