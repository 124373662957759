import React, { useState, useEffect } from 'react';
import Tasks from './Tasks';
// import CreateTask from './CreateTask';
import { ClientService } from '../../../api/ClientService';

function ClientMapping(props) {
    const [tasks, setTasks] = useState([])
    // console.log('Home() tasks', tasks)
    const [numberOfClients, setNumberOfClients] = useState(0)
    const [isTaskEdited, setTaskEdited] = useState(false)
    const [isWalletAdded, setWalletAdded] = useState(false)
    const [isClientnameAdded, setClientnameAdded] = useState(false)
    const [loading, setLoading] = useState(true);
    const [update, setUpdate] = useState(0)
    const clientService = new ClientService();

    useEffect(() => {
        clientService.getAllClients().then(tasks => {
            // console.log('useEffect() tasks', tasks)
            setTasks(tasks)
            setLoading(false)
        }).catch(err => {
            console.log(err)
            setLoading(false)

        });
    }, [
        numberOfClients,
        isTaskEdited,
        isWalletAdded,
        isClientnameAdded,
        update
    ]);

    useEffect(() => {
        // console.log(update + "!!!!!!!")
    },[update]);

    function delTask(clientId) {
        clientService.deleteClient(clientId).then(response => {
            console.log(response)
            setNumberOfClients(numberOfClients - 1)
        });
    }
    
    // function clientCreated() {
    //     setNumberOfClients(numberOfClients + 1)
    // }
    
    function taskEdited(res) {
        setTaskEdited(res)
    }

    function walletAdded(res) {
        setWalletAdded(res)
    }

    function clientnameAdded(res) {
        setClientnameAdded(res)
    }
    return (
        <div>
            {/* <CreateTask clientCreated={clientCreated}></CreateTask> */}
            <Tasks 
                tasks={tasks}
                deleteClient={delTask}
                taskEdited={taskEdited}
                walletAdded={walletAdded}
                clientnameAdded={clientnameAdded}
                update={update}
                setUpdate={setUpdate}
            />
        </div>
    );
}

export default ClientMapping;