import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import { ClientService } from '../../../api/ClientService';
import { Stack, FormControl, Button, Select, MenuItem, Autocomplete, TextField, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CustomizedMultiSelect from '../../../components/CustomizedMultiSelect';
// const useStyles = makeStyles((theme) => ({
//     searchInput: {
//         marginRight: theme.spacing(1),
//     },
// }));
function SourceWallets({rawWalletData, clients, types, currencyList, update, setUpdate, loading, setIsError, setErrorMsg, setIsSuccess, setSuccessMsg}) {
    // const [loading, setLoading] = useState(true);
    // const [rawWalletData, setRawWalletData] = useState([])
    const [curTxIndex, setCurTxIndex] = useState(-1);
    // const [openTxInfo, setOpenTxInfo] = useState(false);
    const [isEditing, setIsEditing] = useState(-1);
    const [walletOnEdit, setWalletOnEdit] = useState();
    // const [types, setTypes] = useState([])
    // const [currencyList, setCurrencyList] = useState([])
    // const [clients, setClients] = useState([])
    const [typeFilter, setTypeFilter] = useState('All');
    const [currencyFilter, setCurrencyFilter] = useState('All');
    const [selectedClientId, setSelectedClientId] = useState();
    // const [txData, setTxData] = useState();
    const [searchValue, setSearchValue] = useState('');
    const [isSearchOpen, setIsSearchOpen] = useState(false);
    const clientService = new ClientService()

    const handleSearchClick = () => {
        setIsSearchOpen(!isSearchOpen);
        setSearchValue('');
    };
    
    const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
    };
    const handleClientMappingOnChange = (e, v) => {
        console.log(v)
        let selected = clients.filter(c => c.clientPrimaryName == v)[0].id
        console.log(selected)
        setSelectedClientId(selected)
    }
    const onConfirmUpdateWalletMapping = async(walletId) => {
        try {
            let counter = update + 1
            let res = await clientService.updateFBWalletClientRelationship(selectedClientId, walletId)
            console.log(res)
            if(res[0] == 1) {
                setUpdate(counter)
                handleCloseEditingWalletMapping()
                setIsError(false)
                setIsSuccess(true)
                setSuccessMsg("Update client wallet mapping successful")
            }
            else {
                setIsSuccess(false)
                setIsError(true)
                setErrorMsg("Update client wallet mapping failed")
            }
        }
        catch(err){
            console.log(err)
            setIsSuccess(false)
            setIsError(true)
            setErrorMsg("Update client wallet mapping failed")
        }
    }
    const handleCloseEditingWalletMapping = () => {
        setIsEditing(-1)
    }
    const handleEditWalletMapping = (index, wallet) => {
        setIsEditing(index)
        setWalletOnEdit(wallet)
    }
    const RawWalletDataRow = (rawWalletDataRow, index) => {
        // console.log('rawFireblocksDataRow', rawFireblocksDataRow)
        return(
            <TableRow hover role="checkbox" tabIndex={-1} key={index} selected={curTxIndex === index}>
                <TableCell align='left'>{rawWalletDataRow.name}</TableCell>
                <TableCell align='left'>{rawWalletDataRow.type}</TableCell>
                <TableCell align='left'>
                    {rawWalletDataRow.walletAddress}
                </TableCell>
                <TableCell align='left'>{rawWalletDataRow.currency}</TableCell>
                <TableCell align='left'>
                    <Stack direction={'row'} spacing={1}>
                        {isEditing === index? 
                            <FormControl sx={{minWidth: 300 }}>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={clients.map(client => client.clientPrimaryName)}
                                    sx={{width: '100%'}}
                                    size='small'
                                    value={walletOnEdit.clientId && clients.filter(c => c.id == walletOnEdit.clientId)[0].clientPrimaryName}
                                    onChange={(e, v) => handleClientMappingOnChange(e, v)}
                                    renderInput={(params) => 
                                    <TextField {...params} placeholder={walletOnEdit.clientId && clients.filter(c => c.id == walletOnEdit.clientId)[0].clientPrimaryName}/>}
                                />
                                {/* <CustomizedMultiSelect value={walletOnEdit.clientId} options={clients.map(client => client.clientPrimaryName)} onChange={(e, v) => handleClientMappingOnChange(e, v)}/> */}
                            </FormControl>
                            :<div style={{display: 'flex', alignItems: 'center'}}>{rawWalletDataRow.clientId && clients.filter(c => c.id === rawWalletDataRow.clientId)[0].clientPrimaryName}</div>}
                        {isEditing === index? 
                        <Stack direction={'row'}>
                            <Button onClick={() => onConfirmUpdateWalletMapping(rawWalletDataRow.id, walletOnEdit.clientId)}>Save</Button>
                            <Button color='error' onClick={() => handleCloseEditingWalletMapping()}>Cancel</Button>
                        </Stack>
                        :<Button onClick={() => handleEditWalletMapping(index, rawWalletDataRow)}>Edit</Button>}
                    </Stack>
                </TableCell>
            </TableRow>
        )
    }
    const handleTypeFilterChange = (e) => {
        setTypeFilter(e.target.value)
    }
    const handleCurrencyFilterChange = (e) => {
        setCurrencyFilter(e.target.value)
    }

    const walletTable = rawWalletData
                            .filter(rw => typeFilter == 'All' ? rw : rw.type == typeFilter)
                            .filter(rw => currencyFilter == 'All' ? rw : rw.currency == currencyFilter)
                            .filter(rw => isSearchOpen? rw.walletAddress.toUpperCase().includes(searchValue.toUpperCase()) : rw)
                            .map((rawWalletDataRow,index) => RawWalletDataRow(rawWalletDataRow,index))
    return loading?
            (<CircularProgress/>)
            :(<div>
            <TableContainer component={Paper} sx={{ maxHeight: 550, width: '100%', margin: 'auto' }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Name</TableCell>
                            <TableCell>
                                <Select disabled={types.length === 0} variant='standard' value={typeFilter} onChange={handleTypeFilterChange} id="demo-simple-select-standard">
                                    <MenuItem value="All">All Types</MenuItem>
                                    {types.map(t => <MenuItem value={t}>{t}</MenuItem>)}
{/*                                     
                                    <MenuItem value="CAD">CAD</MenuItem>
                                    <MenuItem value="EUR">EUR</MenuItem>
                                    <MenuItem value="GBP">GBP</MenuItem> */}
                                </Select>
                            </TableCell>
                            <TableCell align="left">
                                Address
                                <IconButton onClick={handleSearchClick} disabled={rawWalletData.length === 0}>
                                    <SearchIcon />
                                </IconButton>
                                {isSearchOpen && (
                                    <TextField
                                        variant="outlined"
                                        size="small"
                                        value={searchValue}
                                        onChange={handleSearchChange}
                                    />
                                )}
                            </TableCell>
                            <TableCell>
                                <Select disabled={types.length === 0} variant='standard' value={currencyFilter} onChange={handleCurrencyFilterChange} id="demo-simple-select-standard">
                                    <MenuItem value="All">All Currencies</MenuItem>
                                    {currencyList.map(t => <MenuItem value={t}>{t}</MenuItem>)}
{/*                                     
                                    <MenuItem value="CAD">CAD</MenuItem>
                                    <MenuItem value="EUR">EUR</MenuItem>
                                    <MenuItem value="GBP">GBP</MenuItem> */}
                                </Select>
                            </TableCell>
                            <TableCell align="left">Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {walletTable}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>);
}

export default SourceWallets;