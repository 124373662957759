import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import LinearProgress from '@mui/material/LinearProgress';
import IconButton from '@mui/material/IconButton';
import moment from 'moment';
import InfoIcon from '@mui/icons-material/Info';
import TransactionInfoModal from '../../../components/transactionInfoModal';

function RawFortressData({rawData, loading}) {
    const [curTxIndex, setCurTxIndex] = useState(-1);
    const [openTxInfo, setOpenTxInfo] = useState(false);
    const [txData, setTxData] = useState();
    const handleOpenTxInfoDialog = (row, index) => {
        setTxData(row)
        setCurTxIndex(index)
        setOpenTxInfo(true)
    }
    const handleCloseTxInfoDialog = () => {
        setOpenTxInfo(false)
        setCurTxIndex(-1)
    }
    const RawCFDataRow = (rawDataRow, index) => {
        // console.log('rawBcbDataRow', rawBcbDataRow)
        return(
            <TableRow hover role="checkbox" tabIndex={-1} key={index} selected={curTxIndex === index}>
                <TableCell align='left'>{moment(rawDataRow.date_created).format('L')}</TableCell>
                {/* <TableCell align='left'>{rawDataRow.type}</TableCell> */}
                <TableCell align='left'>{rawDataRow.type.includes('Withdrawal') ? '-' + rawDataRow.amount : rawDataRow.amount}</TableCell>
                <TableCell align='left'>{rawDataRow.asset}</TableCell>
                <TableCell align='left'>{rawDataRow.source}</TableCell>
                <TableCell align='left'>{rawDataRow.status}</TableCell>
                <TableCell align='left'>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => handleOpenTxInfoDialog(rawDataRow, index)}
                    >
                        <InfoIcon color="success"/>
                    </IconButton>
                </TableCell>
            </TableRow>
        )
    }
    const fortressTable = rawData.map((rawDataRow,index) => RawCFDataRow(rawDataRow,index))
    if (rawData.length === 0) return null
    else return (
        <div>
            <TableContainer component={Paper} sx={{ maxHeight: 550, width: '100%', margin: 'auto' }}>
                {loading ? <LinearProgress color="success"/> : null}
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Date</TableCell>
                            {/* <TableCell>Type</TableCell> */}
                            <TableCell align="left">Amount</TableCell>
                            <TableCell>Currency</TableCell>
                            <TableCell>Source</TableCell>
                            <TableCell>Status</TableCell>
                            {/* <TableCell><Title level={5}>Routing Type</Title></TableCell> */}
                            <TableCell align="left"/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {fortressTable}
                    </TableBody>
                </Table>
            </TableContainer>
            <TransactionInfoModal 
                openDialog={openTxInfo} 
                closeDialog={handleCloseTxInfoDialog}
                data={txData}
                title={"Fortress Transaction Details"}
            />
        </div>
    );
}

export default RawFortressData;