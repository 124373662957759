/**
 * TalosService Service
 */
export class TalosService {
    constructor() {
        this.API_URL = process.env.REACT_APP_API_URL
    }
    async getAllTalos(){
        const response = await fetch(this.API_URL + '/taloslp');
        return response.json();
    }

}