import React, { useEffect, useState } from 'react';
import { ClientService } from '../../../api/ClientService';
import { TradeTicketService } from '../../../api/TradeTicketService';
import { getCurrentDateAsYYYYMMDD } from '../../utils/dateHelper';
import { getCurrentTradeTicketIDcount } from '../../utils/tradeTicketIDHelper';
import { Stack, Button, Select, MenuItem, FormControl, Grid, TextField, Autocomplete, Snackbar, Paper, Modal, Box, Typography } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import InputAdornment from '@mui/material/InputAdornment';
import TradeTicketsTable from './TradeTicketsTable';
import TradePreviewDialog from './TradePreviewDialog';
import { NumericFormat } from 'react-number-format';
import moment from 'moment';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
const rates = {
    'BTC': {
        decimal: 5,
        'BTC/USD': 2,
        'BTC/CAD': 2,
        'BTC/EUR': 2,
        'BTC/GBP': 2,
        'BTC/USDT': 2,
        'BTC/USDC': 2,
        'BTC/ETH': 4,
    },
    'ETH': {
        decimal: 4,
        'ETH/USD': 2,
        'ETH/GBP': 2,
        'ETH/EUR': 2,
        'ETH/CAD': 2,
        'ETH/USDT': 2,
        'ETH/USDC': 2,
        'ETH/BTC': 5,
    },
    'USDT': {
        decimal: 4,
        'USDT/USD': 4,
        'USDT/CAD': 4,
        'USDT/EUR': 4,
        'USDT/GBP': 4,
        'USDT/USDC': 4,
    },
    'USDC': {
        decimal: 4,
        'USDC/USD': 4,
        'USDC/GBP': 4,
        'USDC/EUR': 4,
        'USDC/CAD': 4,

    },
    'AVAX': {
        decimal: 2,
        'AVAX/USD':2,
        'AVAX/GBP':2,
        'AVAX/EUR':2,
        'AVAX/CAD':2,
        'AVAX/USDT':2,
        'AVAX/USDC':2,
        'AVAX/ETH':2,
    },
    'BNB': {
        decimal: 2,
        'BNB/USD':2,
        'BNB/GBP':2,
        'BNB/EUR':2,
        'BNB/CAD':2,
        'BNB/USDT':2,
        'BNB/USDC':2,
        'BNB/ETH':2,
    },
    'ADA': {
        decimal: 4,
        'ADA/USD':4,
        'ADA/GBP':4,
        'ADA/EUR':4,
        'ADA/CAD':4,
        'ADA/USDT':4,
        'ADA/USDC':4,
        'ADA/ETH':4,
    },
    'LINK': {
        decimal: 3,
        'LINK/USD':3,
        'LINK/GBP':3,
        'LINK/EUR':3,
        'LINK/CAD':3,
        'LINK/USDT':3,
        'LINK/USDC':3,
        'LINK/ETH':4,
    },
    'DOGE': {
        decimal: 5,
        'DOGE/USD':5,
        'DOGE/GBP':5,
        'DOGE/EUR':5,
        'DOGE/CAD':5,
        'DOGE/USDT':5,
        'DOGE/USDC':5,
        'DOGE/ETH':4,
    },
    'LTC': {
        decimal: 3,
        'LTC/USD':3,
        'LTC/GBP':3,
        'LTC/EUR':3,
        'LTC/CAD':3,
        'LTC/USDT':3,
        'LTC/USDC':3,
        'LTC/ETH': 4
    },
    'PAX': {
        decimal: 4,
        'PAX/USD':4,
        'PAX/GBP':4,
        'PAX/EUR':4,
        'PAX/CAD':4,
        'PAX/USDT':4,
        'PAX/USDC':4,
        'PAX/ETH':4,
    },
    'DOT': {
        decimal: 4,
        'DOT/USD':4,
        'DOT/GBP':4,
        'DOT/EUR':4,
        'DOT/CAD':4,
        'DOT/USDT':4,
        'DOT/USDC':4,
        'DOT/ETH':4,
        'DOT/BTC': 5
    },
    'SHIB': {
        decimal: 8,
        'SHIB/USD':8,
        'SHIB/GBP':8,
        'SHIB/EUR':8,
        'SHIB/CAD':8,
        'SHIB/USDT':8,
        'SHIB/USDC':8,
        'SHIB/ETH':8,
        'SHIB/BTC':8,
    },
    'MATIC': {
        decimal: 4,
        'MATIC/USD':4,
        'MATIC/GBP':4,
        'MATIC/EUR':4,
        'MATIC/CAD':4,
        'MATIC/USDT':4,
        'MATIC/USDC':4,
        'MATIC/ETH':4,
        'MATIC/BTC':5
    },
    'SOL': {
        decimal: 3,
        'SOL/USD':3,
        'SOL/GBP':3,
        'SOL/EUR':3,
        'SOL/CAD':3,
        'SOL/USDT':3,
        'SOL/USDC':3,
        'SOL/ETH':4,
        'SOL/BTC':5
    },
    'ATOM': {
        decimal: 3,
        'ATOM/USD':3,
        'ATOM/GBP':3,
        'ATOM/EUR':3,
        'ATOM/CAD':3,
        'ATOM/USDT':3,
        'ATOM/USDC':3,
        'ATOM/BTC':5,
        'ATOM/ETH':4,
    },
}
const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                    name: props.name,
                    value: values.value,
                    },
                });
            }}
            thousandSeparator
            valueIsNumericString
        />
    );
});
const Alert = React.forwardRef(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
function TradeTicketGenerator({curUser, showTable}) {
    const [askingTermsSelect, setAskingTermsSelect] = useState('Fiat'); // initial state for select input
    const askingTermsOptions = ['Fiat', 'Crypto']; // options in the select/pulldown
    const [client, setClient] = useState({})
    const [clientName, setClientName] = useState('');
    const [clientId, setClientId] = useState('');
    const [tradeTicketID, setTradeTicketID] = useState('');
    const [tradingPair, setTradingPair] = useState('BTC/USD');
    const [action, setAction] = useState('Buy');
    const [amount, setAmount] = useState('0');
    const [displayAmount, setDisplayAmount] = useState('0')
    const [amountToTrade, setAmountToTrade] = useState('0');
    const [spread, setSpread] = useState('0');
    const [spotRate, setSpotRate] = useState('0');
    const [displaySpotRate, setDisplaySpotRate] = useState('0');
    const [clientDealtRate, setClientDealtRate] = useState('0');
    const [update, setUpdate] = useState(0);
    const [clientOptions, setClientOptions] = useState([]);
    const [clientNameError, setClientNameError] = useState(null);

    // State for loading and error state of Spread/Fee API call
    const [spreadLoading, setSpreadLoading] = useState(false);
    const [spreadError, setSpreadError] = useState(null);
    // getting trades
    const [rawTrade, setRawTrades] = useState([]);
    const [loading, setLoading] = useState(true);
    // preview dialog
    const [showPreview, setShowPreview] = useState(false);
    const [isError, setIsError] = React.useState(false);
    const [errorMsg, setErrorMsg] = React.useState("");
    const [isSuccess, setIsSuccess] = React.useState(false);
    const [successMsg, setSuccessMsg] = React.useState("");
    const [enableFeeChange, setEnableFeeChange] = React.useState(false);
    const [feeChangeAlert, setFeeChangeAlert] = React.useState(false);
    const [decimalPlace, setDecimalPlace] = React.useState(6);
    const [dealtDecimals,setDealtDecimals] = React.useState(6);
    const [tradingPairOptions, setTradingPairOptions] = useState([]);   // options in the Trading Pairs select/pulldown
    const clientService = new ClientService();
    const tradeService = new TradeTicketService();

    // State for loading and error state of Trading Pairs API call
    useEffect(() => {
        const fetchTradingPairs = async () => {
            try {
                const response = await fetch(process.env.REACT_APP_API_URL + '/tradingpairs');
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                const tradingPairs = data.map(item => ({
                    value: item.trading_pair,
                    label: item.trading_pair,
                }));
                setTradingPairOptions(tradingPairs);
            } catch (error) {
                console.error('There was an error fetching trading pairs!', error);
            }
        };
    
        fetchTradingPairs();
    }, []);
    
    useEffect(() => {
        tradeService.getAllTradeTicket().then(rawTrade => {
            // console.log('useEffect() rawPrimetrust', rawPrimetrust);
            setRawTrades(rawTrade);
            setLoading(false)
        }).catch(err =>{ 
            console.log(err)
            setLoading(false)
        });
    }, [update])
    useEffect(() => {
        // API fetching function
        const getClientNamesFromAPI = async () => {
          // Fetch data from API and return an array of client names
            const clientDataFromAPI = await clientService.getAllClients()
            console.log(clientDataFromAPI)
            // console.log('clientDataFromAPI', clientDataFromAPI);
            let clientDataForAutocomplete = [];
            for (const aClientData of clientDataFromAPI) {
                clientDataForAutocomplete.push({name: aClientData.clientPrimaryName, id: aClientData.id, copperId: aClientData.clientCopperId});
            }
            // console.log('clientDataForAutocomplete', clientDataForAutocomplete);    
            // return ['Client 1', 'Client 2', 'Client 3']; // good for testing without API data
            return clientDataForAutocomplete;
        };
    
        getClientNamesFromAPI().then(data => {
            const options = data.sort((a,b) => a.name.localeCompare(b.name)).map(client => ({ value: client.name, label: client.name, id: client.id, copperId: client.copperId }));
            setClientOptions(options);
        });
    }, []);

    // Effect to fetch Spread/Fee when clientName changes
    useEffect(() => {
        // console.log(client);
        if (!clientName) return; // Don't fetch if no client name selected

        setSpreadLoading(true);
        setSpreadError(null);
        clientService.getSpread(clientName)
        .then(response => {
            console.log('/api/spread response:', parseFloat(response.fee));
            if(!isNaN(parseFloat(response.fee))) {
                // setSpread(response.fee.replace(/[^0-9.]/g, ''));
                setSpread(parseFloat(response.fee));
            }
            else {
                console.log('not a number')
                setSpread("0")
            }
            setSpreadLoading(false);
        })
        .catch(error => {
            setSpreadError(error.message);
            setSpreadLoading(false);
        });
    }, [clientName]);

    useEffect(() => {
        console.log(spread);
        if (spread && spotRate && action) {
            let newClientDealtRate = 0;
            if (action == 'Buy') {
                newClientDealtRate = Number(spotRate) * (1 + Number(spread) / 100);
            } else if (action == 'Sell') {
                newClientDealtRate = Number(spotRate) * (1 - Number(spread) / 100);
            }
            setClientDealtRate(newClientDealtRate.toFixed(dealtDecimals));
        }
    }, [spread, spotRate, action, dealtDecimals]);

    // Event for auto-calculation of Amount to Trade
    useEffect(() => {
        if (amount && clientDealtRate && action && askingTermsSelect) {
            let newAmountToTrade = 0;
            if (askingTermsSelect == 'Fiat') {
                newAmountToTrade = Number(amount) / Number(clientDealtRate);
            } else if (askingTermsSelect == 'Crypto') {
                newAmountToTrade = Number(amount) * Number(clientDealtRate);
            }
            // console.log('newAmountToTrade', newAmountToTrade);
            setAmountToTrade(newAmountToTrade.toFixed(decimalPlace).toString());//.toFixed(2));
        }
    }, [amount, clientDealtRate, action, askingTermsSelect, decimalPlace]);

    // Automatically update TradeTicketID whenever Trading Pair or Action changes
    useEffect(() => {
        getCurrentTradeTicketIDcount().then((tradeTicketNumber) => {
            const [tradingPair1, tradingPair2] = tradingPair.split('/');
            let theTradeTicketID = '';  // string to build Trade Ticket ID
            theTradeTicketID += getCurrentDateAsYYYYMMDD();
            theTradeTicketID += '-';
            theTradeTicketID += action.charAt(0);
            theTradeTicketID += '-';
            theTradeTicketID += tradingPair1;
            theTradeTicketID += tradingPair2;
            // increment Trade Ticket number; last "current" number (tradeTicketNumber) comes as string!
            const currentTradeTicketNumber = 1 + Number(tradeTicketNumber);
            // Pad the tradeTicketNumber to 3 digits and convert back to string
            const paddedTradeTicketNumber = currentTradeTicketNumber.toString().padStart(3, '0');
            theTradeTicketID += paddedTradeTicketNumber;
            // console.log('theTradeTicketID', theTradeTicketID);
            setTradeTicketID(theTradeTicketID);
        });
    }, [tradingPair, action, update]);

    useEffect(() => {
        // if(tradingPair.includes('BTC')){
        //     setDecimalPlace(5)
        //     setDealtDecimals(5)
        //     if(tradingPair == 'BTC/ETH'){
        //         setDealtDecimals(2)
        //     }
        //     // else if(tradingPair.includes('USDT') || tradingPair.includes('USDC')){
        //     //     setDealtDecimals(4)
        //     // }
        //     // else{
        //     //     setDealtDecimals(2)
        //     // }
        // }
        // else if(tradingPair.includes('ETH')){
        //     setDecimalPlace(4)
        //     setDealtDecimals(4)
        // }
        // else if(tradingPair.includes('USDT') || tradingPair.includes('USDC') || tradingPair.includes('ADA') 
        //     || tradingPair.includes('PAX') || tradingPair.includes('DOT') || tradingPair.includes('MATIC')){
        //     setDecimalPlace(4)
        //     setDealtDecimals(4)
        // }
        // else if(tradingPair.includes('LINK') || tradingPair.includes('SOL')){
        //     setDecimalPlace(3)
        //     setDealtDecimals(3)
        // }
        // else if(tradingPair.includes('DODGE')){
        //     setDecimalPlace(5)
        //     setDealtDecimals(5)
        // }
        // else if(tradingPair.includes('SHIB')){
        //     setDecimalPlace(8)
        //     setDealtDecimals(8)
        // }
        // else{
        //     setDecimalPlace(2)
        //     setDealtDecimals(2)
        // }
        const [tradingPair1, tradingPair2] = tradingPair.split('/');
        let rate = rates[tradingPair1].decimal
        let dealt = rates[tradingPair1][tradingPair]
        // askingTermsSelect == 'Fiat'? 
        // action == 'Buy'? parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: dealtDecimal, minimumFractionDigits: dealtDecimal}) + ' ' + tradingPair.split('/')[1]
        // : parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits:dealtDecimal, minimumFractionDigits: dealtDecimal}) + ' ' + tradingPair.split('/')[0]
        // : action == 'Buy'? parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: decimalPlace, minimumFractionDigits: decimalPlace}) + ' ' + tradingPair.split('/')[1]
        // : parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits:decimalPlace, minimumFractionDigits: decimalPlace}) + ' ' + tradingPair.split('/')[0]}</p>
        
        // askingTermsSelect == 'Fiat'? 
        // action == 'Buy'? parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: decimalPlace, minimumFractionDigits: decimalPlace}) + ' ' + tradingPair.split('/')[0]
        // : parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: decimalPlace, minimumFractionDigits: decimalPlace}) + ' ' + tradingPair.split('/')[1]
        // : action == 'Buy'? parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: decimalPlace, minimumFractionDigits: decimalPlace}) + ' ' + tradingPair.split('/')[0]
        // : parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: dealtDecimal, minimumFractionDigits: dealtDecimal}) + ' ' + tradingPair.split('/')[1]
        if(askingTermsSelect == 'Fiat') {
            // if(action == 'Buy'){
            //     setDecimalPlace(rate)
            //     setDealtDecimals(dealt)
            // }
            // else {
            //     setDealtDecimals(rate)
            //     setDecimalPlace(dealt)
            // }
            setDecimalPlace(rate)
            setDealtDecimals(dealt)
        }
        else {
            setDealtDecimals(rate)
            setDecimalPlace(dealt)
            // if(action == 'Buy'){
            //     setDealtDecimals(rate)
            //     setDecimalPlace(dealt)
            // }
            // else {
            //     setDecimalPlace(rate)
            //     setDealtDecimals(dealt)
            // }
        }
    },[tradingPair, action, askingTermsSelect])

    const handleEnableFeeChangeDialog = () => {
        setFeeChangeAlert(true)
    }

    const handleConfirmEnableFeeChange = () => {
        setEnableFeeChange(true)
        setFeeChangeAlert(false)
    }

    async function submittedTradeTicket(res) {
        let result = await tradeService.createTradeTicket(res)
        console.log(result);
        if(result.trade_id){
            // trigger increment function 
            let id_result = await tradeService.updateTradeId();
            setIsError(false)
            setIsSuccess(true)
            setSuccessMsg("Creating trade successfully!")
            return true
        }
        else {
            setIsSuccess(false)
            setIsError(true)
            setErrorMsg("Creating trade failed!")
            return false
        }
    }

    const handleSubmit = async(event) => {
        event.preventDefault();
        let count = update + 1
        if (clientName && !clientOptions.map(option => option.value).includes(clientName)) {
            setClientNameError('Invalid client name. Please select from the dropdown.');
            return;
        }
        
        if (clientName) {
            let ccyDeposited = askingTermsSelect == 'Fiat'? 
                action == 'Buy'? 
                parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: dealtDecimals, minimumFractionDigits: dealtDecimals}) + ' ' + tradingPair.split('/')[1]
                : parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits:decimalPlace, minimumFractionDigits: decimalPlace}) + ' ' + tradingPair.split('/')[0]
                : action == 'Buy'? 
                parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: decimalPlace, minimumFractionDigits: decimalPlace}) + ' ' + tradingPair.split('/')[1]
                : parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits:dealtDecimals, minimumFractionDigits: dealtDecimals}) + ' ' + tradingPair.split('/')[0]
            let ccyPurchased = askingTermsSelect == 'Fiat'? 
                action == 'Buy'? 
                parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: decimalPlace, minimumFractionDigits: decimalPlace}) + ' ' + tradingPair.split('/')[0]
                : parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: dealtDecimals, minimumFractionDigits: dealtDecimals}) + ' ' + tradingPair.split('/')[1]
                : action == 'Buy'? 
                parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: dealtDecimals, minimumFractionDigits: dealtDecimals}) + ' ' + tradingPair.split('/')[0]
                : parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: decimalPlace, minimumFractionDigits: decimalPlace}) + ' ' + tradingPair.split('/')[1]
            let curDate = new Date((new Date()).toLocaleDateString("en-US", {timeZone: "America/New_York"})).toISOString()
            let formattedSpotRate = askingTermsSelect == 'Fiat'? 
                parseFloat(spotRate).toLocaleString(undefined, {minimumFractionDigits: dealtDecimals, maximumFractionDigits: dealtDecimals})
                :parseFloat(spotRate).toLocaleString(undefined, {minimumFractionDigits: decimalPlace, maximumFractionDigits: decimalPlace})
            let formattedDealtRate = askingTermsSelect == 'Fiat'? 
                parseFloat(clientDealtRate).toLocaleString(undefined, {minimumFractionDigits: dealtDecimals, maximumFractionDigits: dealtDecimals})
                :parseFloat(clientDealtRate).toLocaleString(undefined, {minimumFractionDigits: decimalPlace, maximumFractionDigits: decimalPlace})
            console.log(ccyDeposited)
            console.log(ccyPurchased)
            console.log(curDate)
            let result = await submittedTradeTicket({
                clientId,
                clientName,
                tradeTicketID,
                tradingPair,
                action,
                spread,
                amount,
                spotRate: formattedSpotRate,
                clientDealtRate: formattedDealtRate,
                amountToTrade,
                ccyDeposited,
                ccyPurchased,
                date: curDate,
                sender: curUser.nickname
            });
            if(result){
                setShowPreview(false)
                setUpdate(count)
                setAskingTermsSelect('Fiat')
                setClientName('')
                setTradeTicketID('')
                setTradingPair('BTC/USD')
                setAction('Buy')
                setAmount('0')
                setAmountToTrade('0')
                setSpread('0')
                setSpotRate('0')
                setClientDealtRate('0')
                setEnableFeeChange(false)
                setClient({})
            }
          // ...Reset state...
        }
    };
    // console.log('tradingPair', tradingPair);
    return (
        <div>
            <Paper sx={{ width: '60%', margin: 'auto', padding: 5, m: 'auto' }}>
                <Stack spacing={1}>
                    <Stack direction={{xs: 'column', sm: 'row'}} spacing={1}>
                        <div style={{display: 'flex', alignItems: 'center'}}>Direction</div>
                        <FormControl sx={{ minWidth: 120 }}>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={action}
                                onChange={(e) => setAction(e.target.value)} required
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                                sx={{widht: '100%'}}
                                size='small'
                            >
                                <MenuItem value={'Buy'}>Buy</MenuItem>
                                <MenuItem value={'Sell'}>Sell</MenuItem>
                            </Select>
                        </FormControl>
                    </Stack>
                    <Stack direction={{xs: 'column', sm: 'row'}} spacing={1}>
                        <div style={{display: 'flex', alignItems: 'center'}}>Client</div>
                        <FormControl sx={{ m: 1, minWidth: 200}}>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={clientOptions}
                                sx={{width: '100%'}}
                                size='small'
                                value={clientName}
                                onChange={(e, v) => {
                                    console.log(v)
                                    setClient(v)
                                    setClientName(v.value)
                                    setClientId(v.id)
                                }}
                                renderInput={(params) => 
                                <TextField {...params} placeholder="Client Name"/>}
                            />
                        </FormControl>
                        <div style={{display: 'flex', alignItems: 'center'}}>is asking in terms of</div>
                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={askingTermsSelect}
                                onChange={(e) => setAskingTermsSelect(e.target.value)} 
                                required
                                inputProps={{ 'aria-label': 'Without label' }}
                                sx={{widht: '100%'}}
                                size='small'
                            >
                                {askingTermsOptions.map((option) => (
                                    <MenuItem key={option} value={option}>{option}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Stack>
                    <Stack direction={{xs: 'column', sm: 'row'}} spacing={1}>
                        <div style={{display: 'flex', alignItems: 'center'}}>Trading Pair</div>
                        <FormControl sx={{ m: 1, minWidth: 200 }}>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={tradingPairOptions}
                                sx={{width: '100%'}}
                                size='small'
                                // value={clientId}
                                onChange={(e, v) => setTradingPair(v ? v.value : '')} // example of v: {value: 'BTC/CAD', label: 'BTC/CAD'}
                                renderInput={(params) => 
                                <TextField {...params} placeholder="Assets Pair"/>}
                            />
                        </FormControl>
                        <div style={{display: 'flex', alignItems: 'center'}}>Amount</div>
                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                            <TextField 
                                value={amount} 
                                onChange={(e) => {
                                    console.log(e)
                                    setAmount(e.target.value)
                                }}
                                InputProps={{
                                    inputComponent: NumericFormatCustom,
                                    endAdornment: askingTermsSelect === 'Fiat'? tradingPair.split('/')[1]:tradingPair.split('/')[0]
                                }}
                                required
                                size='small'
                            />
                        </FormControl>
                    </Stack>
                    <Stack direction={{xs: 'column', sm: 'row'}} spacing={1}>
                        <div style={{display: 'flex', alignItems: 'center'}}>Spread/Fee (%)</div>
                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                            <TextField 
                                value={spreadLoading ? 'Loading...' : spread} 
                                readOnly={spreadLoading} 
                                onChange={(e) => setSpread(e.target.value)} 
                                error={spreadError}
                                helperText={spreadError ? 'Failed to load spread: ' + {spreadError} : null}
                                required
                                size='small'
                                disabled={!enableFeeChange}
                            />
                        </FormControl>
                        <Button onClick={() => handleEnableFeeChangeDialog()}>Change Fee</Button>
                    </Stack>
                    <Stack direction={{xs: 'column', sm: 'row'}} spacing={1}>
                        <div style={{display: 'flex', alignItems: 'center'}}>Spot Rate</div>
                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                            <TextField 
                                value={spotRate} 
                                onChange={(e) => {
                                    setSpotRate(e.target.value)
                                }}
                                InputProps={{
                                    inputComponent: NumericFormatCustom,
                                }}
                                required
                                size='small'
                            />
                        </FormControl>
                        <div style={{display: 'flex', alignItems: 'center'}}>Client Dealt Rate</div>
                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                            <TextField 
                                value={clientDealtRate}
                                disabled
                                required
                                size='small'
                                InputProps={{
                                    inputComponent: NumericFormatCustom,
                                }}
                            />
                        </FormControl>
                    </Stack>
                    <Stack direction={{xs: 'column', sm: 'row'}} spacing={1}>
                        <div style={{display: 'flex', alignItems: 'center'}}>Amount to Trade</div>
                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                            <TextField 
                                value={Number(amountToTrade).toFixed(decimalPlace)} 
                                disabled
                                required
                                size='small'
                                InputProps={{
                                    inputComponent: NumericFormatCustom,
                                    endAdornment: askingTermsSelect === 'Fiat'? tradingPair.split('/')[0]:tradingPair.split('/')[1]
                                }}
                            />
                        </FormControl>
                        <div style={{display: 'flex', alignItems: 'center'}}>Trade ID</div>
                        <FormControl sx={{ m: 1, minWidth: 220 }}>
                            <TextField 
                                value={tradeTicketID}
                                disabled
                                required
                                size='small'
                                sx={{width: '100%'}}
                                // fullWidth
                            />
                        </FormControl>
                    </Stack>
                    <Stack sx={{ m: 2}}>
                        <Button variant="outlined" onClick={() => setShowPreview(true)}>Preview</Button>
                    </Stack>
                </Stack>
            </Paper>
            {showTable ? 
                <Stack style={{marginTop: '5px'}}>
                    <TradeTicketsTable 
                        tradesData={rawTrade}
                        loading={loading}
                        curUser={curUser}
                        update={update}
                        setUpdate={setUpdate}
                        setErrorMsg={setErrorMsg}
                        setIsError={setIsError}
                        setSuccessMsg={setSuccessMsg}
                        setIsSuccess={setIsSuccess}
                    />
                </Stack>
                :null}
            <Snackbar open={isError} autoHideDuration={6000} onClose={() => setIsError(false)}>
                <Alert severity="error" onClose={() => setIsError(false)}>
                    {errorMsg}
                </Alert>
            </Snackbar>
            <Snackbar open={isSuccess} autoHideDuration={6000} onClose={() => setIsSuccess(false)}>
                <Alert severity="success" onClose={() => setIsSuccess(false)}>
                    {successMsg}
                </Alert>
            </Snackbar>
            <TradePreviewDialog 
                open={showPreview} 
                submitTicket={(e) => handleSubmit(e)}
                handleClose={() => setShowPreview(false)}
                clientName={clientName}
                tradeTicketID={tradeTicketID}
                tradingPair={tradingPair}
                action={action}
                spread={spread}
                amount={amount}
                spotRate={spotRate}
                clientDealtRate={clientDealtRate}
                amountToTrade={amountToTrade}
                askingTermsSelect={askingTermsSelect}
                date={moment().format("MMM DD, YYYY")}
                decimalPlace={decimalPlace}
                dealtDecimal={dealtDecimals}
            />
            <Modal
                open={feeChangeAlert}
                onClose={() => setFeeChangeAlert(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{textAlign: 'center'}}>
                        Confirm to change fee
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    </Typography>
                    <Stack direction={'row'} spacing={1} sx={{justifyContent: 'center'}}>
                        <Button variant='contained' color='primary' onClick={() =>  handleConfirmEnableFeeChange()}>Confirm</Button>
                        <Button variant='contained' color='error' onClick={() =>  setFeeChangeAlert(false)}>Cancel</Button>
                    </Stack>
                    
                </Box>
            </Modal>
        </div>
    );
}

export default TradeTicketGenerator;