import React, { useState, useEffect } from 'react';
import RawBcbData from './RawBcbData';
import BCBCAD from './BCBCAD';
import BCBEUR from './BCBEUR';
import BCBGBP from './BCBGBP';
import BCBCADBlinc from './BCBCADBlinc';
import BCBEURBlinc from './BCBEURBlinc';
import BCBGBPBlinc from './BCBGBPBlinc';
import { BcbService } from '../../../api/BcbService';
import { Box, Tabs, Tab, Snackbar } from '@mui/material';

function BCB(props) {
    const [rawBcb, setRawBcb] = useState([])
    const [loading, setLoading] = useState(true)
    const [activeTab, setActiveTab] = useState(0);
    const [clients, setClients] = useState([])

    const bcbService = new BcbService();
    useEffect(() => {
        bcbService.getAllBcb().then(rawBcb => {
          // console.log('useEffect() rawBcb', rawBcb);
            setRawBcb(rawBcb);
            setLoading(false)
        }).catch(err => {
            console.log(err);
            setLoading(false)
        });
    }, [])

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    }
    
    return (
        // <div>
        //     <RawBcbData
        //         rawBcb={rawBcb}
        //         loading={loading}
        //     />
        // </div>
        <Box
            sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: '100vh', marginTop: 5 }}
        >
            <Tabs
                value={activeTab}
                onChange={handleTabChange}
                orientation="vertical"
                sx={{ borderRight: 1, borderColor: 'divider' }}
                // className={classes.tabsContainer}
            >
                <Tab label="ALL" />
                <Tab label="CAD" />
                <Tab label="EUR" />
                <Tab label="GBP" />
                <Tab label="CAD-BLINK" />
                <Tab label="EUR-BLINK" />
                <Tab label="GBP-BLINK" />
            </Tabs>
            <div style={{width: '100%'}}>
                {activeTab === 0 && <RawBcbData rawBcb={rawBcb} loading={loading}/>}
                {activeTab === 1 && <BCBCAD rawBcb={rawBcb.filter(tx => tx.ticker == 'CAD' && tx.source_name !== 'blinc')} loading={loading}/>}
                {activeTab === 2 && <BCBEUR rawBcb={rawBcb.filter(tx => tx.ticker == 'EUR' && tx.source_name !== 'blinc')} loading={loading}/>}
                {activeTab === 3 && <BCBGBP rawBcb={rawBcb.filter(tx => tx.ticker == 'GBP' && tx.source_name !== 'blinc')} loading={loading}/>}
                {activeTab === 4 && <BCBCADBlinc rawBcb={rawBcb.filter(tx => tx.ticker == 'CAD' && tx.source_name == 'blinc')} loading={loading}/>}
                {activeTab === 5 && <BCBEURBlinc rawBcb={rawBcb.filter(tx => tx.ticker == 'EUR' && tx.source_name == 'blinc')} loading={loading}/>}
                {activeTab === 6 && <BCBGBPBlinc rawBcb={rawBcb.filter(tx => tx.ticker == 'GBP' && tx.source_name == 'blinc')} loading={loading}/>}
            </div>
        </Box>
    );
}

export default BCB;