import React, { useState, useEffect } from 'react';
import RawPrimetrustData from './RawPrimetrustData';
import { PrimetrustService } from '../../../api/PrimetrustService';
import { Box, Tabs, Tab, Snackbar } from '@mui/material';
import SDMPrimeTrust from './SDMPrimeTrust';
import SecureDigitalMarketsPrimeTrust from './SecureDigitalMarketsPrimeTrust';
function PrimeTrust(props) {
    const [rawPrimetrust, setRawPrimetrust] = useState([])
    const [activeTab, setActiveTab] = useState(0);
    const [loading, setLoading] = useState(true)
    const primetrustService = new PrimetrustService();
    useEffect(() => {
        primetrustService.getAllPrimetrust().then(rawPrimetrust => {
            // console.log('useEffect() rawPrimetrust', rawPrimetrust);
            setRawPrimetrust(rawPrimetrust);
            setLoading(false)
        }).catch(err =>{ 
            console.log(err)
            setLoading(false)
        });
    }, [])

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    }
    
    return (
        // <div>
        //     <RawPrimetrustData
        //         rawPrimetrust={rawPrimetrust}
        //         loading={loading}
        //     />
        // </div>
        <Box
            sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: '100vh', marginTop: 5 }}
        >
            <Tabs
                value={activeTab}
                onChange={handleTabChange}
                orientation="vertical"
                sx={{ borderRight: 1, borderColor: 'divider' }}
                // className={classes.tabsContainer}
            >
                <Tab label="ALL" />
                <Tab label="198 (SDM Inc.)" />
                <Tab label="Secure Digital Markets Canada" />
            </Tabs>
            <div style={{width: '100%'}}>
                {activeTab === 0 && <RawPrimetrustData rawPrimetrust={rawPrimetrust} loading={loading}/>}
                {activeTab === 1 && <SDMPrimeTrust rawPrimetrust={rawPrimetrust.filter(tx => tx.account.number === 420072427147)} loading={loading}/>}
                {activeTab === 2 && <SecureDigitalMarketsPrimeTrust rawPrimetrust={rawPrimetrust.filter(tx => tx.account.number === 420071200325)} loading={loading}/>}
            </div>
        </Box>
    );
}

export default PrimeTrust;