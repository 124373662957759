import React, { useState, useEffect } from 'react';
import RawFinclusiveData from './RawFinclusiveData';
import { FinclusiveService } from '../../../api/FinclusiveService';
function Finclusive(props) {
    const [rawData, setRawData] = useState([])
    const [loading, setLoading] = useState(true)
    const finclusiveService = new FinclusiveService();
    useEffect(() => {
        finclusiveService.getAllFinclusive().then(rawCF => {
            setRawData(rawCF);
            setLoading(false)
        }).catch(err => {
            console.log(err);
            setLoading(false)
            
        });
    }, [])
    return (
        <div>
            <RawFinclusiveData
                rawData={rawData}
                loading={loading}
            />
        </div>
    );
}

export default Finclusive;