import React, { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import LaunchIcon from '@mui/icons-material/Launch';
import ClientMapping from './ClientMapping';
import Fireblocks from './Fireblocks';
import PrimeTrust from './PrimeTrust';
import BCB from './BCB';
import ConnectFirst from './ConnectFirst';
import Finclusive from './Finclusive';
import Fortress from './Fortress';
import Openpayd from './Openpayd';
import Talos from './Talos';
import FireblocksWalletMapping from './FireblocksWalletMapping';
import TradeTicketGenerator from './TradeTicketGenerator';
import TradingPairManager from './TradingPairsAdmin';
import { withAuthenticationRequired } from '@auth0/auth0-react';

const BANKS = ['PrimeTrust', 'BCB', 'ConnectFirst', 'Finclusive', 'Fortress', 'Openpayd']

const Adam = ({curUser}) => {
    const [tabIndex, setTabIndex] = useState(0);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedBank, setSelectedBank] = useState(0)
    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (index) => {
        // Perform actions based on the selected menu item
        console.log('Selected menu item:', index);
        setSelectedBank(index)
        handleMenuClose();
    };

    return (
        <div>
            <Tabs value={tabIndex} onChange={handleTabChange}>
                <Tab label="Client Mapping"/>
                <Tab label="Fireblocks Wallet Mapping"/>
                <Tab label="Fireblocks"/>
                <Tab 
                    label={'Banks-' + BANKS[selectedBank]} onClick={handleMenuOpen}
                    icon={<ExpandMoreIcon />}
                    iconPosition="end"
                    aria-controls="dropdown-menu"
                    aria-haspopup="true"/>
                <Tab label="Talos"/>
                <Tab label="Trade Ticket Generator"/>
                <Tab 
                    label={
                        <div>
                            <Link to="/justtradeticketgenerator">
                                <IconButton size="small">
                                    <LaunchIcon />
                                </IconButton>
                            </Link>
                        </div>
                    }
                    aria-label="Just Trade Ticket Generator" 
                />
                <Tab label="Trading Pairs"/>
                {/* <Tab label="PrimeTrust"/>
                <Tab label="BCB"/>
                <Tab label="ConnectFirst"/>
                <Tab label="Finclusive"/>
                <Tab label="Fortress"/>
                <Tab label="Openpayd"/>
                <Tab label="Talos"/>
                <Tab label="Trade Ticket Generator"/>
                <Tab label="Bank Reconciliation"/> */}
                {/* <Tab
                    label="Tab 3"
                    onClick={handleMenuOpen}
                    aria-controls="dropdown-menu"
                    aria-haspopup="true"
                /> */}
            </Tabs>
            <Menu
                id="dropdown-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={() => handleMenuItemClick(0)}>PrimeTrust</MenuItem>
                <MenuItem onClick={() => handleMenuItemClick(1)}>BCB</MenuItem>
                <MenuItem onClick={() => handleMenuItemClick(2)}>ConnectFirst</MenuItem>
                <MenuItem onClick={() => handleMenuItemClick(3)}>Finclusive</MenuItem>
                <MenuItem onClick={() => handleMenuItemClick(4)}>Fortress</MenuItem>
                <MenuItem onClick={() => handleMenuItemClick(5)}>Openpayd</MenuItem>
            </Menu>

            {/* Render the content based on the selected tab */}
            {tabIndex === 0 && <ClientMapping />}
            {tabIndex === 1 && <FireblocksWalletMapping />}
            {tabIndex === 2 && <Fireblocks/>}
            {tabIndex === 3 && selectedBank === 0 && <PrimeTrust/>}
            {tabIndex === 3 && selectedBank === 1 && <BCB />}
            {tabIndex === 3 && selectedBank === 2 && <ConnectFirst/>}
            {tabIndex === 3 && selectedBank === 3 && <Finclusive/> }
            {tabIndex === 3 && selectedBank === 4 && <Fortress />}
            {tabIndex === 3 && selectedBank === 5 && <Openpayd/>}
            {tabIndex === 4 && <Talos/>}
            {tabIndex === 5 && <TradeTicketGenerator curUser={curUser} showTable={true}/>}
            {tabIndex === 6 && <TradingPairManager/>}
        </div>
    );
};

export default withAuthenticationRequired(Adam, {
    onRedirecting: () => (<div>Redirecting you to the login page...</div>)
});
