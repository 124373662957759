import React from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
// import './Landing.css'

function Landing(props) {
    return (
        <div>
            {/* THIS IS LANDING PAGE */}
        </div>
    );
}

export default withAuthenticationRequired(Landing, {
    onRedirecting: () => (<div>Redirecting you to the login page</div>)
});