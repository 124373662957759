/**
 * ConnectFirst Service
 */
export class ConnectFirstService {
    constructor() {
        this.API_URL = process.env.REACT_APP_API_URL
    }
    async getAllConnectFirst(){
        const response = await fetch(this.API_URL + '/connectfirst');
        return response.json();
    }

}