/**
 * FinclusiveService Service
 */
export class FinclusiveService {
    constructor() {
        this.API_URL = process.env.REACT_APP_API_URL
    }
    async getAllFinclusive(){
        const response = await fetch(this.API_URL + '/finclusive');
        return response.json();
    }

}