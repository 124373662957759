import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import LinearProgress from '@mui/material/LinearProgress';
import IconButton from '@mui/material/IconButton';
import moment from 'moment';
import InfoIcon from '@mui/icons-material/Info';
import TransactionInfoModal from '../../../components/transactionInfoModal';
function RawBcbData({rawBcb, loading}) {
    const [curTxIndex, setCurTxIndex] = useState(-1);
    const [openTxInfo, setOpenTxInfo] = useState(false);
    const [txData, setTxData] = useState();

    const handleOpenTxInfoDialog = (row, index) => {
        setTxData(row)
        setCurTxIndex(index)
        setOpenTxInfo(true)
    }
    const handleCloseTxInfoDialog = () => {
        setOpenTxInfo(false)
        setCurTxIndex(-1)
    }
    const RawBcbDataRow = (rawBcbDataRow, index) => {
        // Prepare formatter for currency formatting; commas, dollar-signs..
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        });

        let the_amount = rawBcbDataRow.amount;  // initialize to provided value

        // factor into Amount the value of the Credit column; add a minus for debits
        if (rawBcbDataRow.credit !== "0") // not TRUE, must be a debit
        {
            the_amount = '-' + the_amount; // prepend a minus
        }

        return(
            <TableRow hover role="checkbox" tabIndex={-1} key={index} selected={curTxIndex === index}>
                <TableCell align='left'>{rawBcbDataRow.details_account_name}</TableCell>
                <TableCell align='left'>{rawBcbDataRow.ticker}</TableCell>
                {/* <TableCell align='left'>{rawBcbDataRow.credit === "0" ? 'TRUE': 'FALSE'}</TableCell> */}
                <TableCell align='left'>{formatter.format(the_amount)}</TableCell>
                <TableCell align='left'>{moment(rawBcbDataRow.value_date).format('L')}</TableCell>
                <TableCell align='left'>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => handleOpenTxInfoDialog(rawBcbDataRow.transactionData, index)}
                    >
                        <InfoIcon color="success"/>
                    </IconButton>
                </TableCell>
            </TableRow>
        )
    }

    const bcbTable = rawBcb.map((rawBcbDataRow,index) => RawBcbDataRow(rawBcbDataRow,index))
    if (rawBcb.length === 0) return null
    else return (
        <div>
            <TableContainer component={Paper} sx={{ maxHeight: 550, width: '100%', margin: 'auto' }}>
                {loading ? <LinearProgress color="success"/> : null}
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Account Name</TableCell>
                            <TableCell>Ticker</TableCell>
                            {/* <TableCell align="left">Credit</TableCell> */}
                            <TableCell>Amount</TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell align="left"/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {bcbTable}
                    </TableBody>
                </Table>
            </TableContainer>
            <TransactionInfoModal 
                openDialog={openTxInfo} 
                closeDialog={handleCloseTxInfoDialog}
                data={txData}
                title={"BCB Transaction Details"}
            />
        </div>
    );
}

export default RawBcbData;