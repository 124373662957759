import React, { useState, useEffect } from 'react';
import RawTalosData from './RawTalosData';
import { TalosService } from '../../../api/TalosService';
function Talos(props) {
    const [rawData, setRawData] = useState([])
    const [loading, setLoading] = useState(true)
    const talosService = new TalosService();
    useEffect(() => {
        talosService.getAllTalos().then(rawCF => {
            // console.log('useEffect() rawBcb', rawBcb);
            let sortedDate = rawCF.sort((a, b) => new Date(b.transact_time)- new Date(a.transact_time))
            setRawData(sortedDate);
            setLoading(false)
        }).catch(err => {
            console.log(err);
            setLoading(false)
        });
    }, [])
    return (
        <div>
            <RawTalosData
                rawData={rawData}
                loading={loading}
            />
        </div>
    );
}

export default Talos;