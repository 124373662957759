
export class ClientService {
    constructor() {
        this.API_URL = process.env.REACT_APP_API_URL
    }
    async getSpread(clientname){
        const the_url = this.API_URL + '/spread?clientname=' + clientname;
        const response = await fetch(the_url, {headers: {'Content-Type': 'application/json'},});
        // console.log('ClientService.getSpread() response', response.json());
        return await response.json();
    }

    async getAllClients(){
        const response = await fetch(this.API_URL + '/clients', {
            headers: {'Content-Type': 'application/json'},
        });
        return await response.json();
    }

    async getAllSourceWallets(){
        const response = await fetch(this.API_URL + '/fbsendingwallet', {headers: {'Content-Type': 'application/json'},});
        return await response.json();
    }

    async getAllDestinationWallets(){
        const response = await fetch(this.API_URL + '/fbreceivingwallet', {headers: {'Content-Type': 'application/json'},});
        return await response.json();
    }

    async createClient(data){        
        const response = await fetch(this.API_URL + '/client', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({task: data})
        })
        return await response.json();
    }

    async deleteClient(clientId){
        const response = await fetch(this.API_URL + `/client/${clientId}`, {
            method: 'DELETE'
        })
        return await response.json();
    }

    async editClient(data){
        const response = await fetch(this.API_URL + '/client', {
            method: 'PUT',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({task: data})
        })
        return await response.json();
    }

    async editClientFee(data){
        const response = await fetch(this.API_URL + '/clientfee', {
            method: 'PUT',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({ task: data })
        })
        return await response.json();
    }

    async addWalletTask(data){
        console.log('addWalletTask()', data);
        const response = await fetch(this.API_URL + '/wallet', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({wallet: data})
        })
        return await response.json();
    }

    async addClientnameTask(data){
        console.log('addClientnameTask()', data);
        const response = await fetch(this.API_URL + '/clientname', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({clientname: data})
        })
        return await response.json();
    }

    async updateClientEnity(clientId, entity){
        console.log('updateClientEnity()', clientId, entity);
        const response = await fetch(this.API_URL + '/cliententity', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({clientId: clientId, entity: entity})
        })
        return await response.json();
    }

    async updateClientBanks(clientId, updatedClientBanks, removingClientBanks){
        console.log('updateClientBanks()', clientId);
        let formatedUpdate = updatedClientBanks.map(clientBank => ({
            clientId: clientId,
            bank: clientBank
        }))
        const response = await fetch(this.API_URL + '/clientbanks', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({removedBanks: removingClientBanks, clientBanks: formatedUpdate})
        })
        return await response.json();
    }

    async updateFBWalletClientRelationship(clientId, walletId) {
        console.log('updateFBWalletClientRelationship()', clientId);
        const response = await fetch(this.API_URL + '/updatefbwallet', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({clientId: clientId, walletId: walletId})
        })
        return await response.json();
    }
}