import React, { useState } from 'react';
import { ClientService } from '../../../api/ClientService';
import { useForm } from "react-hook-form";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

function EditFeeModal({adamClient, feeEdited, update, setUpdate}) {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const clientService = new ClientService();

    const { register, handleSubmit } = useForm();

    const onEditSubmit = (data) => {
        let counter = update + 1
        console.log('EditFeeModal() data ', data)
        data.id = adamClient.clientCopperId;
        console.log(data)
        clientService.editClientFee(data).then(response => {
            console.log(response);
            setUpdate(counter)
            setShow(false);
        }).catch(err => {
            //TODO: add error alert
            console.log(err)
        })
    };

    return (
        <>
            <Button variant="contained" color='warning' onClick={() => handleShow()}>Edit Fee</Button>
            <Dialog open={show} onClose={handleClose}>
                <DialogContent>
                <form onSubmit={handleSubmit(onEditSubmit)}>
                        <div className="row" style={{margin: '5px'}}>
                            <div className="form-group col-md-6">
                                <label style={{fontWeight: 'bold'}}>Client's Primary Name: </label>
                                <p>{adamClient.clientPrimaryName}</p>
                            </div>
                        </div>
                        <div className="row" style={{margin: '5px'}}>
                            <div className="form-group col-md-6">
                                <label style={{fontWeight: 'bold'}}>Client's Copper ID: </label>
                                <p>{adamClient.clientCopperId}</p>
                            </div>
                        </div>
                        <div className="row" style={{margin: '5px', position: 'relative', display: 'inline-block'}}>
                            <div className="form-group col-md-3">
                                <label htmlFor="fee" style={{fontWeight: 'bold'}}>Commission/Fee/Spread</label>
                                <input {...register("fee")} type="text" className="form-control" defaultValue={adamClient.adamClientFee && adamClient.adamClientFee.fee} name="fee" id="fee" />
                                <span style={{position: 'absolute', top: '50%', right: '8px', transform: 'translateY(-50%)'}}>%</span>
                            </div>
                        </div>
                        <div className="btncenter">
                            <input type="submit" className="btn btn-danger" />
                        </div>
                    </form>
                </DialogContent>
            </Dialog>
        </>
    );
}

export default EditFeeModal;
