/**
 * TalosService Service
 */
export class TradeTicketService {

    constructor() {
        this.API_URL = process.env.REACT_APP_API_URL
    }

    async createTradeTicket(data){        
        const response = await fetch(this.API_URL + '/sdmtrade', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({data: data})
        })
        return response.json();
    }

    async updateTradeId(){        
        const response = await fetch(this.API_URL + '/ticketgencountincrement')
        return response.json();
    }

    async getAllTradeTicket(){
        const response = await fetch(this.API_URL + '/sdmtrades')
        return response.json();
    }

    async deleteTradeTicket(data){
        const response = await fetch(this.API_URL + '/deletetrade', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({data: data})
        })
        return response.json();
    }
}