import React, { useState, useEffect } from 'react';
import {Tabs, Tab, Snackbar, Box } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import SourceWallets from './SourceWallets';
import DestinationWallets from './DestinationWallets';
import MatchedSourceWallets from './MatchedSourceWallets';
import MatchedDestinationWallets from './MatchedDestinationWallets';
import { ClientService } from '../../../api/ClientService';

// const useStyles = makeStyles((theme) => ({
//     root: {
//         display: 'flex',
//         minHeight: '100vh',
//     },
//     tabsContainer: {
//         borderRight: `1px solid ${theme.palette.divider}`,
//         width: 200,
//         minWidth: 200,
//     },
//     content: {
//         flex: 1,
//         padding: theme.spacing(2),
//     },
// }));
const Alert = React.forwardRef(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
function FireblocksWalletMapping(props) {
    // const classes = useStyles();
    const [activeTab, setActiveTab] = useState(0);
    const [clients, setClients] = useState([])
    //source
    const [rawSourceWalletData, setRawSourceWalletData] = useState([])
    const [updateSource, setUpdateSource] = useState(0)
    const [sourceCurrencyList, setSourceCurrencyList] = useState([])
    const [sourceTypes, setSourceTypes] = useState([])
    const [loadingSource, setLoadingSource] = useState(true)
    // destination
    const [rawDestinationWalletData, setRawDestinationWalletData] = useState([])
    const [updateDestination, setUpdateDestination] = useState(0)
    const [destinationCurrencyList, setDestinationCurrencyList] = useState([])
    const [destinationTypes, setDestinationTypes] = useState([])
    const [loadingDestination, setLoadinggDestination] = useState(true)

    const [isError, setIsError] = React.useState(false);
    const [errorMsg, setErrorMsg] = React.useState("");
    const [isSuccess, setIsSuccess] = React.useState(false);
    const [successMsg, setSuccessMsg] = React.useState("");
    const clientService = new ClientService()
    useEffect(() => {
        getSourceData()
    }, [updateSource])
    useEffect(() => {
        getDestinationData()
    },[updateDestination])
    const getSourceData = async() => {
        let res = await clientService.getAllSourceWallets()
        console.log(res)
        let walletTypes = res.map(w => w.type)
        walletTypes = [...new Set(walletTypes)]
        let currencies = res.map(w => w.currency)
        currencies = [...new Set(currencies)]
        setSourceCurrencyList(currencies)
        await getClients()
        setSourceTypes(walletTypes)
        setRawSourceWalletData(res)
        setLoadingSource(false)
    }
    const getDestinationData = async() => {
        let res = await clientService.getAllDestinationWallets()
        console.log(res)
        let walletTypes = res.map(w => w.type)
        walletTypes = [...new Set(walletTypes)]
        let currencies = res.map(w => w.currency)
        currencies = [...new Set(currencies)]
        setDestinationCurrencyList(currencies)
        // await getClients()
        setDestinationTypes(walletTypes)
        setRawDestinationWalletData(res)
        setLoadinggDestination(false)
    }
    const getClients = async() => {
        let res = await clientService.getAllClients()
        setClients(res)
        console.log(res)
    }
    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    }
    return (
        <Box
            sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: '100vh', marginTop: 5 }}
        >
            <Tabs
                value={activeTab}
                onChange={handleTabChange}
                orientation="vertical"
                sx={{ borderRight: 1, borderColor: 'divider' }}
                // className={classes.tabsContainer}
            >
                <Tab label="Source (Sending) Wallets" />
                <Tab label="Destination (Receiving) Wallets" />
                <Tab label="Matched Source" />
                <Tab label="Matched Destination" />
            </Tabs>
            <div>
                {activeTab === 0 && <SourceWallets rawWalletData={rawSourceWalletData.filter(sourceData => !sourceData.clientId)} clients={clients} types={sourceTypes} currencyList={sourceCurrencyList} update={updateSource} setUpdate={setUpdateSource} loading={loadingSource} setIsError={setIsError} setErrorMsg={setErrorMsg} setIsSuccess={setIsSuccess} setSuccessMsg={setSuccessMsg}/>}
                {activeTab === 1 && <DestinationWallets rawWalletData={rawDestinationWalletData.filter(destData => !destData.clientId)} clients={clients} types={destinationTypes} currencyList={destinationCurrencyList} update={updateDestination} setUpdate={setUpdateDestination} loading={loadingDestination} setErrorMsg={setErrorMsg} setIsSuccess={setIsSuccess} setSuccessMsg={setSuccessMsg}/>}
                {activeTab === 2 && <MatchedSourceWallets rawWalletData={rawSourceWalletData.filter(sourceData => !!sourceData.clientId)} clients={clients} types={sourceTypes} currencyList={sourceCurrencyList} update={updateSource} setUpdate={setUpdateSource} loading={loadingSource} setErrorMsg={setErrorMsg} setIsSuccess={setIsSuccess} setSuccessMsg={setSuccessMsg}/>}
                {activeTab === 3 && <MatchedDestinationWallets rawWalletData={rawDestinationWalletData.filter(destData => !!destData.clientId)} clients={clients} types={destinationTypes} currencyList={destinationCurrencyList} update={updateDestination} setUpdate={setUpdateDestination} loading={loadingDestination} setErrorMsg={setErrorMsg} setIsSuccess={setIsSuccess} setSuccessMsg={setSuccessMsg}/>}
            </div>
            <Snackbar open={isError} autoHideDuration={6000} onClose={() => setIsError(false)}>
                    <Alert severity="error" onClose={() => setIsError(false)}>
                        {errorMsg}
                    </Alert>
                </Snackbar>
                <Snackbar open={isSuccess} autoHideDuration={6000} onClose={() => setIsSuccess(false)}>
                    <Alert severity="success" onClose={() => setIsSuccess(false)}>
                        {successMsg}
                    </Alert>
                </Snackbar>
        </Box>
    );
}

export default FireblocksWalletMapping;