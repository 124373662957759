import React, { useState, useEffect } from 'react';
import EditFeeModal from './EditFeeModal';
import AddWalletModal from './AddWalletModal';
import AddClientnameModal from './AddClientnameModal';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import LinearProgress from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';
import { Button, TextField, Autocomplete, FormControl } from '@mui/material';
import { ClientService } from '../../../api/ClientService';
import CustomizedMultiSelect from '../../../components/CustomizedMultiSelect';
const ENTITIES_LIST =['SDM', 'GL']
const BANK_LIST = ['BCB', 'ConnectFirst', 'Finclusive', 'Fortress', 'Openpayd', 'PrimeTrust', 'Arival']

function Tasks({ tasks, deleteClient, feeEdited, walletAdded, clientnameAdded, loading, update, setUpdate }) {
    const [isEditing, setIsEditing] = useState(-1);
    const [clientOnEdit, setClientOnEdit] = useState();
    const [isEditingBank, setIsEditingBank] = useState(-1);
    const [selectedBanks, setSelectedBanks] = useState([])
    let clientService = new ClientService()
    const handleEditClientEntity = (index, client) => {
        setIsEditing(index)
        setClientOnEdit(client)
        //console.log(ENTITIES_LIST.includes(clientOnEdit.entityForClient))
    }
    const handleClientEntityOnChange = (e, newValue) => {
        console.log(newValue)
        let client = {...clientOnEdit}
        client.entityForClient = newValue.join(', ')
        setClientOnEdit(client)
    }
    const handleCloseEditingClientEnity = () => {
        setIsEditing(-1)
    }
    const handleEditClientBanks = (index, client) => {
        setIsEditingBank(index)
        setClientOnEdit(client)
        let banks = client.adamClientBanks.map(bank => bank.bank)
        console.log(banks)
        setSelectedBanks(banks)
    }
    const handleCloseEditingClientBanks = () => {
        setIsEditingBank(-1)
        setSelectedBanks([])
    }
    const onConfirmUpdateClientEntity = async(clientId, newEntity) => {
        try{
            let counter = update + 1
            let result = await clientService.updateClientEnity(clientId, newEntity)
            console.log(result)
            if(result[0] === 1) {
                setUpdate(counter)
                handleCloseEditingClientEnity()
            }
            else {
                // add alert
            }
            
        }
        catch (err){
            console.log(err)
            // add alert
        }
        
    }
    const onConfirmUpdateClientBanks = async(clientId, existingBanks) => {
        console.log(existingBanks)
        console.log(selectedBanks)
        // let removedBanks = existingBanks.filter(eBanks => !selectedBanks.includes(eBanks.bank))
        // console.log(removedBanks)
        // let existedBank = existingBanks.filter(eBanks => !selectedBanks.includes(eBanks.bank))
        try{
            let counter = update + 1
            let result = await clientService.updateClientBanks(clientId, selectedBanks, existingBanks)
            console.log(result)
            if(result) {
                setUpdate(counter)
                handleCloseEditingClientBanks()
            }
            else {
                // add alert
            }
            
        }
        catch (err){
            console.log(err)
            // add alert
        }
        
    }
    const handleClientBankSelectionChange = (e, value) => {
        console.log(value)
        setSelectedBanks(value)
    }
    const TaskRow = (task, index) => {
        return(
            <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                <TableCell>{task.id}</TableCell>
                <TableCell align='left'>{task.clientPrimaryName}</TableCell>
                {/* <TableCell align='left'>{task.clientCopperId}</TableCell> */}
                <TableCell align='left'>
                    <Stack direction={'row'} spacing={1}>
                        {isEditing === index? 
                            <FormControl sx={{minWidth: 150 }}>
                                <CustomizedMultiSelect defaultValues={clientOnEdit.entityForClient ? clientOnEdit.entityForClient.split(', ').filter(str => str !== ''): []} options={ENTITIES_LIST} onChange={(e, v) => handleClientEntityOnChange(e, v)}/>
                                {/* <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={ENTITIES_LIST}
                                    sx={{width: '100%'}}
                                    size='small'
                                    value={clientOnEdit.entityForClient}
                                    onChange={(e, v) => handleClientEntityOnChange(v)}
                                    renderInput={(params) => 
                                    <TextField {...params} placeholder={task.entityForClient}/>}
                                /> */}
                            </FormControl>
                            :<div style={{display: 'flex', alignItems: 'center'}}>{task.entityForClient}</div>}
                        {isEditing === index? 
                        <Stack direction={'row'}>
                            <Button onClick={() => onConfirmUpdateClientEntity(clientOnEdit.id, clientOnEdit.entityForClient)}>Save</Button>
                            <Button color='error' onClick={() => handleCloseEditingClientEnity()}>Cancel</Button>
                        </Stack>
                        :<Button onClick={() => handleEditClientEntity(index, task)}>Edit</Button>}
                    </Stack>
                </TableCell>
                <TableCell align='left'>
                    <Stack direction={'row'} spacing={1}>
                        {isEditingBank === index ?
                            <FormControl sx={{minWidth: 150 }}>
                                <CustomizedMultiSelect defaultValues={selectedBanks} options={BANK_LIST} onChange={(e, v) => handleClientBankSelectionChange(e, v)}/>
                            </FormControl>
                            :<div style={{display: 'flex', alignItems: 'center'}}>{task.adamClientBanks.map(bankObj => bankObj.bank).join(', ')}</div>}
                        {isEditingBank === index? 
                        <Stack direction={'row'}>
                            <Button disabled={task.adamClientBanks.map(bankObj => bankObj.bank).length === 0 && selectedBanks.length === 0} onClick={() => onConfirmUpdateClientBanks(clientOnEdit.id, clientOnEdit.adamClientBanks)}>Save</Button>
                            <Button color='error' onClick={() => handleCloseEditingClientBanks()}>Cancel</Button>
                        </Stack>
                            :<Button onClick={() => handleEditClientBanks(index, task)}>Edit</Button>}
                    </Stack>
                    
                </TableCell>
                <TableCell align='left'>{task.adamClientFee ? parseFloat(task.adamClientFee.fee) + "%" : ''}</TableCell>
                {/* <TableCell align='left'>{task.adamClientnames.length}</TableCell>
                <TableCell align='left'>{task.adamWallets.length}</TableCell> */}
                <TableCell align='left'>
                    <Stack spacing={1}>
                        <EditFeeModal adamClient={task} feeEdited={feeEdited} update={update} setUpdate={setUpdate}/>
                        <AddWalletModal adamClient={task} walletAdded={walletAdded} update={update} setUpdate={setUpdate}/>
                        <AddClientnameModal adamClient={task} clientnameAdded={clientnameAdded} update={update} setUpdate={setUpdate}/>
                        {/* <Button variant="contained" color='error' onClick={(e) => deleteClient(task.id)}>Delete</Button> */}
                    </Stack>
                </TableCell>
            </TableRow>
        )
    }
    const clientTable = tasks.map((task,index) => TaskRow(task,index))
    if (tasks.length === 0) return null
    else return (
        <>
            <TableContainer component={Paper} sx={{ maxHeight: 550, width: '100%', margin: 'auto' }}>
                {loading ? <LinearProgress color="success"/> : null}
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell>ADAM ID</TableCell>
                            <TableCell align="left">Client's Primary Name</TableCell>
                            {/* <TableCell align="left">Client's Copper ID</TableCell> */}
                            <TableCell align="left">Our Entity for Client</TableCell>
                            <TableCell align="left">Bank</TableCell>
                            <TableCell align="left">Client's Commission Fee</TableCell>
                            {/* <TableCell align="left">Alternate Names</TableCell>
                            <TableCell align="left">Wallets</TableCell> */}
                            <TableCell align="left">Actions</TableCell>    
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {clientTable}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

export default Tasks;