import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import { ClientService } from '../../../api/ClientService';
import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

function AddClientnameModal({adamClient, clientnameAdded}) {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const clientService = new ClientService();

    const { register, handleSubmit, reset } = useForm();
    
    const onClientnameAddSubmit = (data) => {
        console.log('AddClientnameModal() => onClientnameAddSubmit => data ', data)
        data.adamClientId = adamClient.id;
        clientService.addClientnameTask(data).then(response => {
            clientnameAdded(response);
            setShow(false);
            // clear the modal fields upon submission
            reset();
        });
    };

    let new_clientname = {};
    new_clientname.client_name = '';
    new_clientname.name_source = '';

    return (
        <>
            <Button size="small" variant="contained" color='secondary' onClick={handleShow}>
                Add Name
            </Button>
            <Dialog open={show} onClose={handleClose}>
                <DialogContent>
                    <form onSubmit={handleSubmit(onClientnameAddSubmit)}>
                        <div className="row" style={{margin: '5px'}}>
                            <div className="form-group col-md-6">
                                <label style={{fontWeight: 'bold'}}>Client Name:</label>
                                <p>{adamClient.clientPrimaryName}</p>
                            </div>
                        </div>
                        <div className="row" style={{margin: '5px'}}>
                            <div className="form-group col-md-6">
                                <label htmlFor="name_source" style={{fontWeight: 'bold'}}>Source of Name</label>
                                <select {...register("name_source")} className="form-control" name="name_source" id="name_source">
                                    <option value="">Select...</option>
                                    <option value="Fireblocks">Fireblocks</option>
                                    <option value="BCB">BCB</option>
                                    <option value="ConnectFirst">ConnectFirst</option>
                                    <option value="Finclusive">Finclusive</option>
                                    <option value="Fortress">Fortress</option>
                                    <option value="Openpayd">Openpayd</option>
                                    <option value="PrimeTrust">PrimeTrust</option>
                                    <option value="Arival">Arival</option>
                                </select>
                            </div>
                        </div>
                        <div className="row" style={{margin: '5px'}}>
                            <div className="form-group col-md-6">
                                <label htmlFor="client_name" style={{fontWeight: 'bold'}}>Alternate Name for Client</label>
                                <input {...register("client_name")} type="text" className="form-control" defaultValue={new_clientname.client_name} name="client_name" id="client_name" placeholder="Alternate Name for Client" />
                            </div>
                        </div>
                        <div className="btncenter">
                            <input type="submit" className="btn btn-danger" />
                        </div>
                    </form>
                </DialogContent>
            </Dialog>
        </>
    );
}

export default AddClientnameModal;
