import { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import moment from 'moment';
import InfoIcon from '@mui/icons-material/Info';
import TransactionInfoModal from '../../../components/transactionInfoModal';
function RawFireblocksData({rawFireblocks}) {
    const [curTxIndex, setCurTxIndex] = useState(-1);
    const [openTxInfo, setOpenTxInfo] = useState(false);
    const [txData, setTxData] = useState();
    const handleOpenTxInfoDialog = (row, index) => {
        setTxData(row)
        setCurTxIndex(index)
        setOpenTxInfo(true)
    }
    const handleCloseTxInfoDialog = () => {
        setOpenTxInfo(false)
        setCurTxIndex(-1)
    }

    const RawFireblocksDataRow = (rawFireblocksDataRow, index) => {
        // console.log('rawFireblocksDataRow', rawFireblocksDataRow)
        return(
            <TableRow hover role="checkbox" tabIndex={-1} key={index} selected={curTxIndex === index}>
                {/* <TableCell>{rawFireblocksDataRow.fireblocksdata.id}</TableCell> */}
                <TableCell align='left' sx={{width: '10%'}}>{rawFireblocksDataRow.fireblocksdata.note}</TableCell>
                <TableCell align='left'>{rawFireblocksDataRow.fireblocksdata.source.name}</TableCell>
                <TableCell align='left'>{rawFireblocksDataRow.fireblocksdata.destination.name}</TableCell>
                <TableCell align='left'>{rawFireblocksDataRow.fireblocksdata.assetId}</TableCell>
                <TableCell align='left'>{Number(rawFireblocksDataRow.fireblocksdata.amount).toLocaleString()}</TableCell>
                <TableCell align='left'>{moment(rawFireblocksDataRow.fireblocksdata.createdAt).format('L')}</TableCell>
                <TableCell align='left'>{rawFireblocksDataRow.fireblocksdata.createdBy}</TableCell>
                <TableCell align='left'>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => handleOpenTxInfoDialog(rawFireblocksDataRow, index)}
                    >
                        <InfoIcon color="success"/>
                    </IconButton>
                </TableCell>
            </TableRow>
        )
    }
    const fireblocksTable = rawFireblocks.map((rawFireblocksDataRow,index) => RawFireblocksDataRow(rawFireblocksDataRow,index))
    if (rawFireblocks.length === 0) return null
    else return (
        <div>
            <TableContainer component={Paper} sx={{ maxHeight: 550, width: '100%', margin: 'auto' }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {/* <TableCell>Id</TableCell> */}
                            <TableCell align="left" sx={{width: '10%'}}>Note</TableCell>
                            <TableCell align="left">Source</TableCell>
                            <TableCell align="left">Destination</TableCell>
                            <TableCell align="left">Asset</TableCell>
                            <TableCell align="left">Amount</TableCell>
                            <TableCell align="left">Date</TableCell>
                            <TableCell align="left">Created By</TableCell>
                            <TableCell align="left">Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {fireblocksTable}
                    </TableBody>
                </Table>
            </TableContainer>
            <TransactionInfoModal 
                openDialog={openTxInfo} 
                closeDialog={handleCloseTxInfoDialog}
                data={txData}
                title={"Fireblocks Transaction Details"}
            />
        </div>
    );
}

export default RawFireblocksData;