import axios from 'axios';
const BACKEND_API = process.env.REACT_APP_API_URL
export const getCurrentTradeTicketIDcount = async () => {
    try {
        const response = await axios.get(BACKEND_API + '/ticketgenlastcount', {
        headers: {
            Accept: 'application/json',
        }
        });
        // console.log('response.data', response.data);
        return response.data.last_count;
    } catch (error) {
        console.error('Failed to fetch TradeTicketID from API', error);
        return '';
    }
};