import * as React from 'react';
import './App.css';
import {
  BrowserRouter as Router, Routes, Route, useNavigate, Navigate, useSearchParams, useLocation
} from 'react-router-dom';

//import { useShell } from 'react-ssh2-hook';
import Lukka from './pages/Lukka';
import Account from './pages/Account';
import Adam from './pages/adam';
import TradeTicketGenerator from './pages/adam/TradeTicketGenerator';
import Landing from './pages/Landing';
import Developer from './pages/Developer';
import Trader from './pages/Trader';
import News from './pages/News';
// import TabPanel from './pages/adam';
import Header from './components/Header';
import Error from './pages/Error'
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios'
import { react } from '@babel/types';
import { getUserRoles, getCustomerUsers, getNews } from './api/authService';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const AUDIENCE = process.env.REACT_APP_AUDIENCE;

function App() {
  // to check the current route/location and conditionally display the Header based on that
  const location = useLocation();

  const navigate = useNavigate();

  const {
    user,
    isAuthenticated,
    loginWithRedirect,
    getAccessTokenSilently,
  } = useAuth0();

  const [userId, setUserId] = React.useState()
  const [roles, setRoles] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [searchParams] = useSearchParams()
  const [token, setToken] = React.useState()
  const [isTalosUser, setIsTalosUser] = React.useState()

  React.useEffect(() => {
    const redirect = searchParams.get('redirect')
    if (redirect === 'true'){
      setLoading(false)
    }
    // redirect to talos if user is customer user
    // if (roles.includes('CustomerUser')) {
    //   // window.location = 'http://trade-sandbox.sdm.co/'
    //   window.location = 'http://trade.sdm.co/'
    // }
  }, [roles])

  React.useEffect(() => {
    if (user) {
      setUserId(user.sub)
      getToken()
    }
  }, [user])

  React.useEffect(() => {
    const getData = async () =>  {
      if (userId){
        setLoading(true)
        const response = await getUserRoles(userId)
        setLoading(false)
        setUserRoles(response)
      }
    }
    getData()
  }, [userId]) 

  React.useEffect(() => {
    const getUsers = async () => {
      const data = await getCustomerUsers()
      const currentUserEmail = user.email
      const userIsTalosUser = data.users.find(user => user['Email'] == currentUserEmail)? true: false
      setIsTalosUser(userIsTalosUser)
    }
    if(user){
      getUsers()
    }
  }, [user])

  const getToken = async () => {
    const token = await getAccessTokenSilently({
      detailedResponse: true,
      audience: AUDIENCE,
    })
    setToken(token)
  }

  const setUserRoles = (response) => {
    if (response.result) {
      let updatedRoles = [...roles] 
      response.result.forEach(role => {
        const newRole = role.name
        if (!roles.includes(newRole)) {
          updatedRoles.push(newRole)
        }
      })
      setRoles(updatedRoles)
    }
  }

  return (
    <div className="App">
    {!loading ? 
      <>
        {user && location.pathname !== "/justtradeticketgenerator" && (
          <Header roles={roles} isTalosUser={isTalosUser} />
        )}
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path='/account' element={<Account user={user} token={token} roles={roles} isTalosUser={isTalosUser} />} />
          <Route path='/dashboard' element={<Lukka />} />
          <Route 
            path='/dev-dashboard' 
            element={roles.includes('Developer')? <Developer/>: <div className='heading'>Must be a developer to access this page</div>}
          />
          <Route 
            path='/adam-dashboard'
            element={<Adam curUser={user}/>} 
          />
          {/* <Route 
            path='/adam-dashboard-new'
            element={<TabPanel />} 
          /> */}
          <Route path='/news' element={<News getNews={getNews}/>}/>
          <Route path="/justtradeticketgenerator" element={<TradeTicketGenerator />} />
          <Route path='/error' element={<Error/>}/>
        </Routes>
      </>
    :
      <>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </>
      }
    </div>
  );
}

export default App;
